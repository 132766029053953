const SPINNERS = {
  SET_SOCIAL_QUERY: 'SET_SOCIAL_QUERY',
  SET_CLASSIFICATION_QUERY: 'SET_CLASSIFICATION_QUERY',
  SET_CONTACT_STATUS_QUERY: 'SET_CONTACT_STATUS_QUERY',
  SET_CONTACT_STATUS_INFLUNCERS_QUERY: 'SET_CONTACT_STATUS_INFLUNCERS_QUERY',
  SET_DEPARTMENTS_QUERY: 'SET_DEPARTMENTS_QUERY',
  SET_ROLES_QUERY: 'SET_ROLES_QUERY',
  SET_COUNTRIES_QUERY: 'SET_COUNTRIES_QUERY',
  SET_GOVERMENTES_QUERY: 'SET_GOVERMENTES_QUERY',
  SET_CITIES_QUERY: 'SET_CITIES_QUERY',
  SET_NATIONALITIES_QUERY: 'SET_NATIONALITIES_QUERY',
  SET_LANGUAGES_QUERY: 'SET_LANGUAGES_QUERY',
  SET_PHONE_TYPE_QUERY: 'SET_PHONE_TYPE_QUERY',
  SET_INTERESTS_QUERY: 'SET_INTERESTS_QUERY',
  SET_MARITAL_STATUS_QUERY: 'SET_MARITAL_STATUS_QUERY',
  SET_CAMPAIGNS_CONTACTS_TYPES_QUERY: 'SET_CAMPAIGNS_CONTACTS_TYPES_QUERY',
  SET_PRIORITY_TYPES_QUERY: 'SET_PRIORITY_TYPES_QUERY',
  SET_TASK_TYPES_QUERY: 'SET_TASK_TYPES_QUERY',

};

export default SPINNERS;
