import layoutModule from './layout.module';
import chatModule from './chat.module';
import chatboxModule from './chatbox.module';
import authModule from './auth.module';
import campaignsModule from './campaigns.module';
import modalsModule from './modals.module';
import contactsModule from './contacts.module';
import employeesModule from './employees.module';
import spinnersModule from './spinners.module';
import mediaModule from './media.module';
import phoneModule from './phone.module';
import homeModule from './home.module';
import tasksModule from './tasks.module';
import complainsModule from './complains.module';
import scrapeModule from './scrape.module';
import reviewModule from './review.module';
import tapbsModule from './tasbs.module';

const modules = {
  layout: layoutModule,
  chat: chatModule,
  chatbox: chatboxModule,
  auth: authModule,
  campaigns: campaignsModule,
  modals: modalsModule,
  contacts: contactsModule,
  employees: employeesModule,
  spinners: spinnersModule,
  media: mediaModule,
  phone: phoneModule,
  home: homeModule,
  tasks: tasksModule,
  complains: complainsModule,
  scrape: scrapeModule,
  review: reviewModule,
  tabs: tapbsModule,
};

export default modules;
