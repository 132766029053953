import { PHONE } from '../types';

export default {
  state: {
    phoneStatusQuery: null,
    waScreenQuery: null,
  },
  getters: {},
  mutations: {
    [PHONE.SET_PHONE_STATUS](state, payload) {
      state.phoneStatusQuery = payload;
    },
    [PHONE.SET_PHONE_WA_SCREEN](state, payload) {
      state.waScreenQuery = payload;
    },
  },
  actions: {

  },

};
