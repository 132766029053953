<template>
  <div
    class="bg-bg-light p-5 md:p-6 lg:p-8 border border-primary-dark border-solid rounded shadow-md"
  >
    <h1 class="text-lg font-medium mb-6 md:mb-10 text-primary">{{ header }}</h1>
    <slot> </slot>
  </div>
</template>

<script>
export default {
  props: {
    header: String,
  },
};
</script>
