const LAYOUT = {
  SET_LOWER_CHAT_INPUT_HEIGHT: 'SET_LOWER_CHAT_INPUT_HEIGHT',
  SET_CHATBOX_DROWER_OPENED: 'SET_CHATBOX_DROWER_OPENED',
  SET_CHAT_USERINFO_DROWER_OPENED: 'SET_CHAT_USERINFO_DROWER_OPENED',
  SET_CHAT_ACTIVE_USER: 'SET_CHAT_ACTIVE_USER',
  SET_ACTIVE_USER_INFO: 'SET_ACTIVE_USER_INFO',
  SET_ACTIVE_SIDE_ACTION: 'SET_ACTIVE_SIDE_ACTION',
  SET_DARK_MODE: 'SET_DARK_MODE',
};

export default LAYOUT;
