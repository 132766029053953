import apiAxios, { setEventIdHeader } from '../apiAxios';

// APIS

const fetchConversationContent = async ({ id, type, page = 1 }) => {
  // id => contact or group id
  // type => contact 0 or group  1
  const chatType = !type || type === 'contact' ? '0' : '1';
  const eventID = localStorage.getItem('eventID');
  setEventIdHeader(eventID);
  const { data } = await apiAxios.get(`/conversation/${chatType}/${id}?page=${page}&event_id=${eventID}&chat=open`);
  return data;
};

const getChatMessage = async (id, body) => {
  const { data } = await apiAxios.get(`/get_chat/${id}`, body);
  return data;
};

export default {

  fetchConversationContent, getChatMessage,

};
