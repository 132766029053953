// import handleChatPagesData from '@/utils/handleChatPagesData';
import { CHAT } from '../types';

const handleChatBoxScroll = (isLoading) => {
  if (typeof document === 'undefined') {
    return;
  }
  let cinterval = null;
  let intrvCount = 0;
  const elem = document.getElementById('chatbox__chat');

  if (elem) {
    cinterval = setInterval(() => {
      if (cinterval && !isLoading && intrvCount > 5) {
        clearInterval(cinterval);
        intrvCount = 0;
      }
      const sc = (elem.scrollHeight || elem.clientHeight) + 100;
      elem.scrollTo(0, sc);
      intrvCount += 1;
    }, 100);
  }
};

const handleRefreshContactsQuery = (state) => {
  if (state.contactsQuery) {
    state.contactsQuery.refetch();
  }
};

// const calcScrollPsition = () => {
//   const chatBox = document.querySelector('#chatbox__chat > div > div');
//   const lastMsg = document.querySelector('#chatbox__chat .messages__page:last-child .message__message:last-child');

// };

const handleRefreshChatContentQuery = (state, id) => {
  // deprecated
  if (id) return;
  if (state.chatContentQuery) {
    const chatId = state.chatContentQuery.data?.pages[0]?.data?.contact?.pry_id;
    // const chatData = handleChatPagesData(state.chatContentQuery);

    if (id && chatId && id === chatId) {
      // refresh 1st page
      state.chatContentQuery.refetch(({ refetchPage: (page, index) => index === 0 }));
    }
    handleChatBoxScroll(state.chatContentQuery.isLoading || false);
  }
};

export default {
  state: {
    contactsQuery: null,
    contactsSelectQuery: null,
    chatContentQuery: null, // deprecated
    chatSearchMessageQuery: null,
    replyMessage: null,
    forwordMessages: [],
    newGroupMembers: [],
    newForwordMembers: [],
    selectMode: false,
    selectedMessages: [],
    quickRepliesQuery: null,
    chatInternalSearchQuery: null,
    uploadFile: null,
    selectedUsers: null, // null => no select users mode ||| [] => active select mode
  },
  getters: {},
  mutations: {
    [CHAT.SET_CHAT_UPLOAD_FILE_STATE](state, payload) {
      state.uploadFile = payload;
    },
    [CHAT.SET_CHAT_CONTACT_STATE](state, payload) {
      state.contactsQuery = payload;
    },
    [CHAT.SET_CHAT_CONTACT_SELECT_STATE](state, payload) {
      state.contactsSelectQuery = payload;
    },
    [CHAT.SET_CHAT_CONTENT_STATE](state, payload) {
      state.chatContentQuery = payload;
    },
    [CHAT.SET_CHAT_SEARCH_MESSAE_STATE](state, payload) {
      state.chatSearchMessageQuery = payload;
    },
    [CHAT.SET_REPLY_MESSAGE_STATE](state, payload) {
      state.replyMessage = payload;
    },
    [CHAT.REFRESH_CHAT_QUERIES]() {
      // if (state.contactsQuery) {
      //   state.contactsQuery.refetch();
      // }
      // if (state.chatContentQuery) {
      //   state.chatContentQuery.refetch();
      //   handleChatBoxScroll(state.chatContentQuery.isLoading || false);
      // }
    },

    [CHAT.REFRESH_SOCKET_QUERIES](state, payload) {
      // render-conversation-list
      // render-conversation-message-list
      switch (payload.action_type) {
        case 'render-conversation-list':
          handleRefreshContactsQuery(state);
          break;
        case 'render-conversation-message-list':
          handleRefreshContactsQuery(state);
          handleRefreshChatContentQuery(state, payload.chat_id);
          break;

        default:
          break;
      }
    },
    [CHAT.CHATBOX_SCROLL_DOWN](state) {
      if (state.chatContentQuery) {
        handleChatBoxScroll(state.chatContentQuery.isLoading || false);
      }
    },
    [CHAT.GROUP_MEMBERS_ADD](state, payload) {
      let isInArr = false;
      state.newGroupMembers.forEach((m) => {
        if (m.phone === payload.phone) {
          isInArr = true;
        }
      });
      if (!isInArr) {
        state.newGroupMembers.push(payload);
      }
    },
    [CHAT.GROUP_MEMBERS_REMOVE](state, payload) {
      const membs = state.newGroupMembers.filter((m) => m.phone !== payload);
      state.newGroupMembers = membs;
    },
    [CHAT.GROUP_MEMBERS_RESET](state) {
      state.newGroupMembers = [];
    },
    [CHAT.FORWORD_MEMBERS_ADD](state, payload) {
      let isInArr = false;
      state.newForwordMembers.forEach((m) => {
        if (m.phone === payload.phone) {
          isInArr = true;
        }
      });
      if (!isInArr) {
        state.newForwordMembers.push(payload);
      }
    },
    [CHAT.FORWORD_MEMBERS_REMOVE](state, payload) {
      const membs = state.newForwordMembers.filter((m) => m.phone !== payload);
      state.newForwordMembers = membs;
    },
    [CHAT.FORWORD_MEMBERS_RESET](state) {
      state.forwordMessages = [];
      state.newForwordMembers = [];
      state.selectedMessages = [];
      state.selectMode = false;
    },
    [CHAT.FORWORD_SET_MESSAGE](state) {
      state.forwordMessages = state.selectedMessages;
      state.newForwordMembers = [];
    },
    [CHAT.CHAT_SELECT_MODE](state, payload) {
      state.selectMode = payload;
    },
    [CHAT.CHAT_SELECT_MESSAGES](state, payload) {
      state.selectMode = true;
      // toggle message
      const msgs = [...state.selectedMessages];
      let isIn = false;
      msgs.forEach((mss) => {
        if (mss.message && mss.message.id && payload.message && payload.message.id) {
          if (mss.message.id === payload.message.id) {
            isIn = true;
          }
        }
      });
      if (isIn) {
        // remove
        const newMessags = msgs.filter((mess) => mess.message?.id !== payload?.message?.id);
        state.selectedMessages = newMessags;
      } else {
        // add
        state.selectedMessages = payload ? [...state.selectedMessages, payload] : [...state.selectedMessages];
      }
    },
    [CHAT.SET_CHAT_GUICK_REPLIES_STATE](state, payload) {
      state.quickRepliesQuery = payload;
    },
    [CHAT.SET_CHAT_INTERNAL_SEARCH_STATE](state, payload) {
      state.chatInternalSearchQuery = payload;
    },
    [CHAT.SET_CHAT_SET_SELECTED_USERS](state, payload) {
      if (!payload) {
        state.selectedUsers = null;
        return;
      }
      let selected = state.selectedUsers ? [...state.selectedUsers] : [];
      if (selected.includes(payload)) {
        // un check
        selected = selected.filter((s) => s !== payload);
      } else {
        selected.push(payload);
      }
      state.selectedUsers = selected;
    },
  },
  actions: {},
};
