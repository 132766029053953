import { useQuery } from 'vue-query';
import apiAxios from '../apiAxios';

const postLogin = async (body) => {
  await apiAxios.get('/sanctum/csrf-cookie');
  const { data } = await apiAxios.post('/login', body);

  return data;
};

const postLogout = async () => {
  const { data } = await apiAxios.post('/signout');
  return data;
};
const getUser = async () => {
  const { data } = await apiAxios.get('/me');
  return data;
};

const getUserRoles = async () => {
  const { data } = await apiAxios.get('/roles');
  return data;
};

const useUserQuery = () => useQuery(['me'], getUser);

export default {
  postLogin,
  getUser,
  getUserRoles,
  postLogout,
  // ///////
  useUserQuery,
};
