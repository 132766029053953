const CHAT = {
  SET_CHAT_CONTACT_STATE: 'SET_CHAT_CONTACT_STATE',
  SET_CHAT_CONTACT_SELECT_STATE: 'SET_CHAT_CONTACT_SELECT_STATE',
  SET_CHAT_CONTENT_STATE: 'SET_CHAT_CONTENT_STATE',
  SET_REPLY_MESSAGE_STATE: 'SET_REPLY_MESSAGE_STATE',
  SET_FAKE_MESSAGE_DATA: 'SET_FAKE_MESSAGE_DATA',
  REFRESH_CHAT_QUERIES: 'REFRESH_CHAT_QUERIES',
  REFRESH_SOCKET_QUERIES: 'REFRESH_SOCKET_QUERIES',
  CHATBOX_SCROLL_DOWN: 'CHATBOX_SCROLL_DOWN',
  GROUP_MEMBERS_ADD: 'GROUP_MEMBERS_ADD',
  GROUP_MEMBERS_REMOVE: 'GROUP_MEMBERS_REMOVE',
  GROUP_MEMBERS_RESET: 'GROUP_MEMBERS_RESET',
  FORWORD_MEMBERS_ADD: 'FORWORD_MEMBERS_ADD',
  FORWORD_MEMBERS_REMOVE: 'FORWORD_MEMBERS_REMOVE',
  FORWORD_MEMBERS_RESET: 'FORWORD_MEMBERS_RESET',
  FORWORD_SET_MESSAGE: 'FORWORD_SET_MESSAGE',
  CHAT_SELECT_MODE: 'CHAT_SELECT_MODE',
  CHAT_SELECT_MESSAGES: 'CHAT_SELECT_MESSAGES',
  SET_CHAT_SEARCH_MESSAE_STATE: 'SET_CHAT_SEARCH_MESSAE_STATE',
  SET_CHAT_GUICK_REPLIES_STATE: 'SET_CHAT_GUICK_REPLIES_STATE',
  SET_CHAT_INTERNAL_SEARCH_STATE: 'SET_CHAT_INTERNAL_SEARCH_STATE',
  SET_CHAT_UPLOAD_FILE_STATE: 'SET_CHAT_UPLOAD_FILE_STATE',
  SET_CHAT_SET_SELECTED_USERS: 'SET_CHAT_SET_SELECTED_USERS',
};
export default CHAT;
