import store from '@/store/index';

/* eslint-disable import/prefer-default-export */
import { destroyToken, getToken, saveToken } from '@/services/jwt.service';
import { toRaw } from 'vue';

export const pagesGurd = async (to, from, next) => {
  const token = getToken();
  if (!token) {
    next('/auth/login');
    return;
  }

  const { roles } = to.meta;
  const res = toRaw(await store.state.auth.userData);

  if (!res || !res) {
    // eslint-disable-next-line no-shadow
    const { token } = store.state.auth;
    if (token) {
      saveToken(token);
      next();
    } else {
      destroyToken();
      next('/auth/login');
    }
    return;
  }

  const userRole = res?.role_id;
  if (!userRole) {
    if (token) {
      saveToken(token);
      next();
    } else {
      destroyToken();
      next('/auth/login');
    }
    return;
  }

  const isAllowed = roles.includes(userRole);
  if (isAllowed) {
    next();
  } else {
    next('/chat');
  }
};

export const authGurd = async (to, from, next) => {
  const token = getToken();

  if (token) {
    next('/chat');
    return;
  }
  next();
  return;
};
