import { SPINNERS } from '../types';

export default {
  state: {
    classificationQuery: null,
    contactStatusQuery: null,
    contactStatusIfluncersQuery: null,
    socialQuery: null,
    departmentsQuery: null,
    rolesQuery: null,
    countriesQuery: null,
    govermentsQuery: null,
    citiesQuery: null,
    nationalitiesQuery: null,
    languagesQuery: null,
    phoneTypesQuery: null,
    interestsQuery: null,
    maritalStatusQuery: null,
    campaignsContactTypesQuery: null,
    priorityTypesQuery: null,
    taskTypesQuery: null,

  },
  getters: {},
  mutations: {
    [SPINNERS.SET_CLASSIFICATION_QUERY](state, payload) {
      state.classificationQuery = payload;
    },
    [SPINNERS.SET_CONTACT_STATUS_QUERY](state, payload) {
      state.contactStatusQuery = payload;
    },
    [SPINNERS.SET_CONTACT_STATUS_INFLUNCERS_QUERY](state, payload) {
      state.contactStatusIfluncersQuery = payload;
    },
    [SPINNERS.SET_SOCIAL_QUERY](state, payload) {
      state.socialQuery = payload;
    },
    [SPINNERS.SET_DEPARTMENTS_QUERY](state, payload) {
      state.departmentsQuery = payload;
    },
    [SPINNERS.SET_ROLES_QUERY](state, payload) {
      state.rolesQuery = payload;
    },
    [SPINNERS.SET_COUNTRIES_QUERY](state, payload) {
      state.countriesQuery = payload;
    },
    [SPINNERS.SET_GOVERMENTES_QUERY](state, payload) {
      state.govermentsQuery = payload;
    },
    [SPINNERS.SET_CITIES_QUERY](state, payload) {
      state.citiesQuery = payload;
    },
    [SPINNERS.SET_NATIONALITIES_QUERY](state, payload) {
      state.nationalitiesQuery = payload;
    },
    [SPINNERS.SET_LANGUAGES_QUERY](state, payload) {
      state.languagesQuery = payload;
    },
    [SPINNERS.SET_PHONE_TYPE_QUERY](state, payload) {
      state.phoneTypesQuery = payload;
    },
    [SPINNERS.SET_INTERESTS_QUERY](state, payload) {
      state.interestsQuery = payload;
    },
    [SPINNERS.SET_MARITAL_STATUS_QUERY](state, payload) {
      state.maritalStatusQuery = payload;
    },
    [SPINNERS.SET_CAMPAIGNS_CONTACTS_TYPES_QUERY](state, payload) {
      state.campaignsContactTypesQuery = payload;
    },
    [SPINNERS.SET_PRIORITY_TYPES_QUERY](state, payload) {
      state.priorityTypesQuery = payload;
    },
    [SPINNERS.SET_TASK_TYPES_QUERY](state, payload) {
      state.taskTypesQuery = payload;
    },

  },
  actions: {},
};
