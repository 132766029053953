import { COMPLAINS } from '../types';

export default {
  state: {
    complainsQuery: null,
  },
  getters: {},
  mutations: {
    [COMPLAINS.SET_COMPLAINS_QUERY](state, payload) {
      state.complainsQuery = payload;
    },
  },
  actions: {},
};
