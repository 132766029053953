import { HOME } from '../types';

export default {
  state: {
    homeStatisticsQuery: null,
    homeRecentChatsQuery: null,
  },
  getters: {
    homeRecentChatsData(state) {
      if (!state.homeRecentChatsQuery || !state.homeRecentChatsQuery.data || !state.homeRecentChatsQuery.data.data) {
        return null;
      }
      const { data } = state.homeRecentChatsQuery.data;
      const newData = data.map((el) => {
        const type = el.to_type === 0 ? 'contact' : 'group';
        return {
          ...el,
          user: el[type],
        };
      });

      return { ...state.homeRecentChatsQuery.data, data: newData };
    },
  },

  mutations: {
    [HOME.SET_HOME_STATS](state, payload) {
      state.homeStatisticsQuery = payload;
    },
    [HOME.SET_HOME_RECENT_CHATS](state, payload) {
      state.homeRecentChatsQuery = payload;
    },
  },
  actions: {

  },

};
