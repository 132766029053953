import Echo from 'laravel-echo';
import CONFIG from '../config';

const handleSocket = async () => {
  if (!CONFIG.PUSHER.APP_KEY) {
    console.error('Pusher app key is missing. Please provide the app key in your configuration.');
    return;
  }

  // eslint-disable-next-line global-require
  window.Pusher = require('pusher-js');

  window.Echo = new Echo({
    broadcaster: 'pusher',
    key: CONFIG.PUSHER.APP_KEY,
    cluster: CONFIG.PUSHER.CLUSTER,
    encrypted: true,
    authEndpoint: '/broadcasting/auth',
    auth: {
      headers: {
        Authorization: 'Bearer 3c5f2a9e9c73af8f4583760539542041',
      },
    },
  });

  // Rest of your code...
};

export default { handleSocket };
