import { MODALS } from '../types';

export default {
  state: {
    chatHeadPlusModal: null,
    chatHeadOptionsModal: null, // 'campaigns',
    assignModal: null, // or data
    complainModal: null, // or data
  },
  getters: {},
  mutations: {
    [MODALS.SET_MODALS_HEAD_PLUS](state, payload) {
      state.chatHeadPlusModal = payload || null;
    },
    [MODALS.SET_MODALS_HEAD_OPTIONS](state, payload) {
      state.chatHeadOptionsModal = payload || null;
    },
    [MODALS.SET_MODALS_ASSIGN](state, payload) {
      state.assignModal = payload || null;
    },
    [MODALS.SET_MODALS_COMPLAIN](state, payload) {
      state.complainModal = payload || null;
    },

  },
  actions: {},
};
