import { LAYOUT } from '../types';

export default {
  state: {
    lowerChatInputMessageHeight: 110,
    chatBoxDrawerOpened: false,
    chatUserInfoDrawerOpened: false, // info , search
    activeChatUser: null,
    activeUserInfo: null,
    activeSideAction: 'search', // search, add_group, forword
    darkMode: true,
  },
  getters: {},
  mutations: {
    [LAYOUT.SET_LOWER_CHAT_INPUT_HEIGHT](state) {
      if (typeof document !== 'undefined') {
        const el = document.querySelector('.chatbox__footer');
        if (!el || state.lowerChatInputMessageHeight === el.clientHeight) {
          return;
        }
        state.lowerChatInputMessageHeight = el.clientHeight;
      }
    },
    [LAYOUT.SET_CHAT_ACTIVE_USER](state, payload) {
      state.activeChatUser = payload;
    },
    [LAYOUT.SET_ACTIVE_USER_INFO](state, payload) {
      state.activeUserInfo = payload;
    },
    [LAYOUT.SET_CHATBOX_DROWER_OPENED](state, payload) {
      state.chatBoxDrawerOpened = payload;
    },
    [LAYOUT.SET_CHAT_USERINFO_DROWER_OPENED](state, payload) {
      state.chatUserInfoDrawerOpened = payload;
    },
    [LAYOUT.SET_ACTIVE_SIDE_ACTION](state, payload) {
      state.activeSideAction = payload || 'search';
      if (payload === 'forword') {
        state.chatBoxDrawerOpened = false;
        state.chatUserInfoDrawerOpened = false;
      }
    },
    [LAYOUT.SET_DARK_MODE](state, payload) {
      state.darkMode = payload;
    },
  },
  actions: {},

};
