<template>
  <Form
    layout="vertical"
    class="min-w-[300px] md:min-w-[400px] max-w-full"
    :model="user"
    name="login"
  >
    <FormItem
      label="Email Address"
      :rules="[{ required: true, message: 'Please input your email!' }]"
      :validate-status="errors.email && 'error'"
      :help="errors.email"
    >
      <Input v-model:value="user.email" placeholder="Email" size="large" type="email" name="email" />
    </FormItem>
    <FormItem
      label="Password"
      :rules="[{ required: true, message: 'Please input your password!' }]"
      :validate-status="errors.password && 'error'"
      :help="errors.password"
    >
      <InputPassword
        v-model:value="user.password"
        placeholder="password"
        size="large"
        type="password"
        name="password"
      />
    </FormItem>
    <FormItem>
      <div :class="formType === 'internal' && 'flex justify-end'" class="mt-6 md:mt-8">
        <Button
          size="large"
          type="primary"
          :class="formType === 'internal' ? ' pbtn-wide' : 'w-full block'"
          :disabled="(!user.email || !user.password) && firestLoad"
          :loading="isLoading"
          @click.prevent="login"
        >Login</Button>
      </div>
      <div v-if="error" class="mt-3 w-full">
        <Alert type="error" :message="error" banner />
      </div>
    </FormItem>
  </Form>
</template>

<script>
import { Button, Alert, Form, Input, message } from 'ant-design-vue';
// import { setAxiosHeader } from '@/services/apiAxios';
import { AUTH } from '../../store/types';

export default {
  props: {
    formType: {
      type: String,
      default: 'auth',
    },
  },
  components: {
    Button,
    Alert,
    Form,
    Input,
    FormItem: Form.Item,
    InputPassword: Input.Password,
  },
  data() {
    return {
      formState: '',
      user: {
        email: 'dave29@example.net',
        password: '',
      },
      errors: {
        email: null,
        password: null,
      },
      error: '',
      firestLoad: false,
      isLoading: false,
    };
  },

  updated() {
    this.firestLoad = true;
  },
  methods: {
    validEmail(email) {
      // eslint-disable-next-line operator-linebreak
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validateForm() {
      this.firestLoad = true;
      if (!this.user.email) {
        this.errors.email = 'Email is required';
      } else if (!this.validEmail(this.user.email)) {
        this.errors.email = 'Email is not valid';
      } else {
        this.errors.email = null;
      }

      if (!this.user.password) {
        this.errors.password = 'Password is required';
      } else if (this.user.password.length < 5) {
        this.errors.password = 'Password is not valid';
      } else {
        this.errors.password = null;
      }
    },
    async login() {
      this.isLoading = true;
      this.validateForm();

      if (Object.values(this.errors).every((attr) => attr === null)) {
        if (this.user.email && this.user.password) {
          const resp = await this.$store.dispatch(AUTH.ACTION_LOGIN, {
            email: this.user.email,
            password: this.user.password,
          });

          if (resp.data) {
            this.$gates.setRoles(resp.data.role_name);
            // this.$router.replace('/');
            this.$router.replace('/office');
          }
          if (resp.error) {
            message.error(resp.error);
          }
        }
      }
      this.isLoading = false;
    },
  },
};
</script>
