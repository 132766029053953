import { SCRAPE } from '../types';

export default {
  state: {
    scrapeSearchQuery: null,
  },
  getters: {
    scrapedUserData(state) {
      if (!state.scrapeSearchQuery) {
        return null;
      }

      return state.scrapeSearchQuery.data;
    },
  },
  mutations: {
    [SCRAPE.SET_SCRAPE_SEARCH_QUERY](state, payload) {
      state.scrapeSearchQuery = payload;
    },
  },
  actions: {},
};
