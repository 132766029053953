import { TASKS } from '../types';

export default {
  state: {
    tasksQuery: null,
    taskQuery: null,
    taskTypesQuery: null,
  },
  getters: {},
  mutations: {
    [TASKS.SET_TASKS_QUERY](state, payload) {
      state.tasksQuery = payload;
    },
    [TASKS.SET_TASK_TYPES_QUERY](state, payload) {
      state.taskTypesQuery = payload;
    },
    [TASKS.SET_TASK_STATE](state, payload) {
      state.taskQuery = payload;
    },
  },
  actions: {},
};
