<template>
  <div class="min-h-screen w-full flex items-center justify-center py-10">
    <AuthCard header="Login to Your Account">
      <LoginForm />
    </AuthCard>
  </div>
</template>

<script>
import AuthCard from '@/components/auth/AuthCard.vue';
import LoginForm from '@/components/auth/LoginForm.vue';

export default {
  components: { AuthCard, LoginForm },
};
</script>
