<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav>-->
  <router-view />
</template>

<script>
import SOCKET from '@/services/apis/socket';

import { initDarkMode } from './utils/handleDarkMode';
import { AUTH } from './store/types';
import { getToken } from './services/jwt.service';

export default {
  beforeCreate() {
    initDarkMode();
    if (!this.$store.state.auth.isAuth) {
      this.$store.commit(AUTH.SET_AUTH, getToken());
    }
  },
  computed: {
    isAuth() {
      return this.$store.state.auth.isAuth;
    },
  },
  watch: {
    isAuth(val) {
      if (!val && !this.$route.path.startsWith('/auth/')) {
        // watch logout
        this.$router.push('/auth/login');
      }
    },
  },
  mounted() {
    SOCKET.handleSocket();
  },
};
</script>
