const CONFIG = {
  // API_URL: 'https://wafinal.grand-community.com/api/v1',
  // API_URL: 'https://dev.grand-community.com/api/v1',
  // API_URL: 'http://127.0.0.1:8000/api/v1',
  // API_URL: 'https://wadev.grand-community.com/api/v1',
  // API_URL: 'https://wts-server.grand-community.com/api/v1',
  API_URL: 'https://wafinal.grand-community.com/api/v1',

  SOCKET: {
    // URL: 'https://wafinal.grand-community.com:6001',
    URL: 'https://wafinal.grand-community.com:6009',
    appId: '35c287599f564a3a',
    Authorization: 'Bearer 3c5f2a9e9c73af8f4583760539542041',
    // channel: 'laravel_database_whatsapp_chat',
    channel: 'whatsapp_chat',

    sEvent: '.msgSent',
  },
  PUSHER: {
    APP_ID: '1631290',
    APP_KEY: 'a497aab62d0bf6f51cff',
    APP_SECRET: 'cbc5588d5f3beab537cc',
    CLUSTER: 'ap2',
    ENCRYPTED: true,
    HOST: 'grand.pusher.com',
    PORT: 443,
    SCHEME: 'https',
    PUSHER_CHANNEL: 'whatsapp_chat',
    PUSHER_EVENT: '.msgSent',
  },
};

export default CONFIG;
