import { REVIEW } from '../types';

export default {
  state: {
    reviewQuery: null,
  },
  getters: {},
  mutations: {
    [REVIEW.SET_REVIEW_STATE](state, payload) {
      state.reviewQuery = payload;
    },
  },
  actions: {},
};
