// fetchin and caching chat conversation data

import chatboxApi from '@/services/apis/chatbox.api';
import { CHATBOX } from '../types';
import { setEventIdHeader } from '@/services/apiAxios';

// const isInQuery = () => { }

const handleScroll = () => {
  setTimeout(() => {
    const chatBox = document.querySelector('#chatbox__chat');
    if (chatBox) {
      const heightBx = chatBox.scrollHeight * 2;
      chatBox.scrollBy(0, heightBx);
    }
  }, 100);
};

const getPageIndex = (pageNum, pages) => pages.findIndex((p) => p.id === pageNum);

// query [  {
//     id: chatID
//     isLoading
//     isFetching: pageNum || false
//     error
//     pages [{ id: pagenum , data: [conversation data ]}]
//     contact
//     group
//     scrollId: msgId (of ),
//     filterData
//   }]
export default {
  state: {
    query: [],
    maxCash: 10,
    isNewMessage: false,
  },
  getters: {},
  mutations: {
    [CHATBOX.SET_CHATBOX_FETCH_INITIATE](state, { id, page, scrollId }) {
      const qs = [...state.query];

      const qIndex = qs.findIndex((q) => q.id === id);

      if (qIndex !== -1) {
        qs[qIndex].error = null;
        qs[qIndex].isFetching = page || true;
        if (getPageIndex(page, qs[qIndex].pages) === -1) {
          // page not exict
          const newPage = { id: page, data: null };
          const newPages = [...qs[qIndex].pages, newPage].sort((a, b) => b.id - a.id);
          qs[qIndex].pages = newPages;
        }
      } else {
        const newQ = {
          id, isLoading: true, isFetching: false, error: null, pages: [{ id: page, data: [] }], contact: null, group: null, scrollId: scrollId || null,
        };
        qs.unshift(newQ);
      }

      state.query = qs;
    },
    [CHATBOX.SET_CHATBOX_FETCH_SUCCESS](state, { id, page, data }) {
      let qs = [...state.query];

      const qIndex = qs.findIndex((q) => q.id === id);
      qs[qIndex].isLoading = false;
      qs[qIndex].isFetching = false;
      qs[qIndex].error = null;
      qs[qIndex].contact = data.contact || null;
      qs[qIndex].group = data.group || null;
      qs[qIndex].lastPage = data.conversations.last_page || null;
      qs[qIndex].perPage = data.conversations.per_page || null;
      let { scrollId } = qs[qIndex];
      const newPages = qs[qIndex].pages.map((p) => {
        const newPage = { ...p };
        if (p.id === page) {
          if (!scrollId) {
            scrollId = data.conversations.data[0].id;
          }
          newPage.data = data.conversations.data.reverse();
        }
        return newPage;
      });
      qs[qIndex].pages = newPages.sort((a, b) => b.id - a.id);
      qs[qIndex].scrollId = scrollId;
      /// delete extra quers
      if (qs.length > state.maxCash) {
        qs = qs.slice(0, state.maxCash);
      }

      state.query = qs;
    },
    [CHATBOX.SET_CHATBOX_FETCH_ERROR](state, { id, error }) {
      const qs = [...state.query];
      const qIndex = qs.findIndex((q) => q.id === id);
      qs[qIndex].isLoading = false;
      qs[qIndex].isFetching = false;
      qs[qIndex].error = error;
    },
    [CHATBOX.SET_CHATBOX_SCROLLID](state, { id, scrollId }) {
      if (scrollId) {
        const scId = scrollId === 'delete' ? null : scrollId;
        const qs = [...state.query];
        const qIndex = qs.findIndex((q) => q.id === id);
        if (qIndex !== -1) {
          qs[qIndex].scrollId = scId;
        }
        state.query = qs;
      }
    },
    [CHATBOX.SET_CHATBOX_IS_NEW_MESSAGE](state, payload) {
      state.isNewMessage = !!payload;
    },

  },
  actions: {
    async fetchPage({ commit, state }, params) {
      const { id, type, page, refetch, scrollId, scrollDown } = params;

      if (scrollDown) {
        handleScroll();
      } else {
        commit(CHATBOX.SET_CHATBOX_SCROLLID, { id, scrollId });
      }

      const chatIndex = state.query.findIndex((q) => q.id === id);
      const pages = state.query[chatIndex]?.pages;
      if (pages && getPageIndex(page, pages) !== -1 && !refetch) {
        // check if loaded this page before

        return;
      }
      // const lastPage = state.query[chatIndex]?.lastPage;
      // console.log('lastPage', state.query[chatIndex]?.lastPage);
      // console.log('perPage', state.query[chatIndex]?.perPage);
      // console.log('lastPageLength', state.query[chatIndex]?.pages[lastPage - 1]?.data?.length);
      // if (lastPage && pages && lastPage <= pages.length) {
      //   return;
      // }

      commit(CHATBOX.SET_CHATBOX_FETCH_INITIATE, { id, page, scrollId });
      try {
        const res = await chatboxApi.fetchConversationContent({ id, type, page });
        if (res.data) {
          commit(CHATBOX.SET_CHATBOX_FETCH_SUCCESS, { id, page, data: res.data });
          if (scrollDown) {
            handleScroll();
          }
        } else {
          commit(CHATBOX.SET_CHATBOX_FETCH_ERROR, { id, page, error: 'Error in getting data from conversation api' });
        }
      } catch (error) {
        commit(CHATBOX.SET_CHATBOX_FETCH_ERROR, { id, page, error: error.response.data.message || error.message });
      }
    },

    // eslint-disable-next-line consistent-return
    async fetchFilteredMsg(_, params) {
      // eslint-disable-next-line camelcase
      const { filter_key, search_input, message_id } = params;

      try {
        const res = await chatboxApi.getChatMessage(message_id, { filter_key, search_input });
        return res.data || null;
      } catch (error) {
        //
      }
    },

    async refreshChatSocket({ dispatch, commit }, params) {
      // rootState
      const { socket, userId, scrollDown } = params;
      // console.log('socket', socket);
      if (!socket || !userId || socket.action_type !== 'render-conversation-message-list') {
        return;
      }
      const eventId = socket.event_id;
      setEventIdHeader(eventId);
      console.log("EventIDFromBox", eventId);
      localStorage.setItem('eventID', eventId)
      // conversation_id
      // const user = state.query.find((d) => d.id === userId);
      // if (!user) {
      //   return;
      // }
      const type = userId.includes('@c.us') ? 'contact' : 'group';
      // const cId = user[type]?.pry_id;

      if (socket.conversation_id === userId) {
        dispatch('fetchPage', { id: userId, type, page: 1, refetch: true }).then(() => {
          if (scrollDown) {
            handleScroll();
          } else {
            commit(CHATBOX.SET_CHATBOX_IS_NEW_MESSAGE, true);
          }
        });
      }
      // eslint-disable-next-line consistent-return
      return eventId;
    },
  },
};
