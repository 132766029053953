import { CAMPAIGNS } from '../types';

export default {
  state: {
    campaignsQuery: null,
    campaignQuery: null,
    campaignsPagesQuery: null,
    activeCampaignsQuery: null,
    campaignTypeQuery: null,
    campaignBranchesQuery: null,
    brandsQuery: null,
    subBrandsQuery: null,
    brandBranchesQuery: null,
    campaignExcludeQuery: null,
    campaignObjectiveQuery: null,
    campaignCoverageQuery: null,
    confirmedCampaignsQuery: null,
    campaignsContactsQuery: null,
    rejectReasonsQuery: null,
    allCampaignsQuery: null,
    campaignContactsQuery: null,
    campaignStatusListQuery: null,
  },
  getters: {},
  mutations: {
    [CAMPAIGNS.SET_CAMPAIGNS_STATE](state, payload) {
      state.campaignsQuery = payload;
    },
    [CAMPAIGNS.SET_CAMPAIGN_STATE](state, payload) {
      state.campaignQuery = payload;
    },
    [CAMPAIGNS.SET_CAMPAIGNS_PAGES_STATE](state, payload) {
      state.campaignsPagesQuery = payload;
    },
    [CAMPAIGNS.SET_ACTIVE_CAMPAIGNS_QUERY](state, payload) {
      state.activeCampaignsQuery = payload;
    },
    [CAMPAIGNS.SET_CAMPAIGN_TYPE_QUERY](state, payload) {
      state.campaignTypeQuery = payload;
    },
    [CAMPAIGNS.SET_CAMPAIGN_BRANCHES_QUERY](state, payload) {
      state.campaignBranchesQuery = payload;
    },
    [CAMPAIGNS.SET_BRAND_STATE](state, payload) {
      state.brandsQuery = payload;
    },
    [CAMPAIGNS.SET_SUB_BRAND_STATE](state, payload) {
      state.subBrandsQuery = payload;
    },
    [CAMPAIGNS.SET_BRAND_BRANCH_STATE](state, payload) {
      state.brandBranchesQuery = payload;
    },
    [CAMPAIGNS.SET_CAMPAIGNS_EXCLUDE_STATE](state, payload) {
      state.campaignExcludeQuery = payload;
    },
    [CAMPAIGNS.SET_CAMPAIGNS_OBJECTIVE_STATE](state, payload) {
      state.campaignObjectiveQuery = payload;
    },
    [CAMPAIGNS.SET_CAMPAIGNS_COVERAGE_STATE](state, payload) {
      state.campaignCoverageQuery = payload;
    },
    [CAMPAIGNS.SET_CONFIRMED_CAMPAIGNS_STATE](state, payload) {
      state.confirmedCampaignsQuery = payload;
    },
    [CAMPAIGNS.SET_CAMPAIGNS_CONTACT_STATE](state, payload) {
      state.campaignsContactsQuery = payload;
    },
    [CAMPAIGNS.SET_REJECT_REASONS_QUERY](state, payload) {
      state.rejectReasonsQuery = payload;
    },
    [CAMPAIGNS.SET_ALL_CAMPAIGNS_QUERY](state, payload) {
      state.allCampaignsQuery = payload;
    },
    [CAMPAIGNS.SET_CAMPAIGN_CONTACTS_QUERY](state, payload) {
      state.campaignContactsQuery = payload;
    },
    [CAMPAIGNS.SET_CAMPAIGN_STATUS_LIST_QUERY](state, payload) {
      state.campaignStatusListQuery = payload;
    },

  },
  actions: {},
};
