import { CONTACTS } from '../types';

export default {
  state: {
    contactsQuery: null,
  },
  getters: {},
  mutations: {
    [CONTACTS.SET_CONTACTS_QUERY](state, payload) {
      state.contactsQuery = payload;
    },
  },
  actions: {},
};
