import authApi from '@/services/apis/auth.api';
// import { setAxiosHeader } from '@/services/apiAxios';
import { destroyToken, saveToken } from '@/services/jwt.service';
import { message } from 'ant-design-vue';
import { AUTH } from '../types';

export default {
  state: {
    isAuth: false,
    token: null,
    userQuery: null,
    userData: null,
    isSendingMessage: false,
    officeId: null,
  },
  getters: {
    setIsSendingMessage(state) {
      return state.isSendingMessage;
    },
    getOfficeId: (state) => state.officeId, // Getter to retrieve the officeId

  },
  mutations: {
    setOfficeId(state, officeId) {
      state.officeId = officeId; // Update the officeId state
    },
    [AUTH.SET_AUTH](state, token) {
      if (token) {
        state.isAuth = true;
        state.token = token;
        saveToken(token);
        // setAxiosHeader(token);
      } else {
        state.isAuth = false;
        state.token = null;
        destroyToken();
      }
    },
    [AUTH.SET_ACTIVE_USER](state, payload) {
      state.userQuery = payload;
    },
    [AUTH.SET_USER_DATA](state, payload) {
      state.userData = payload;
    },
    updateIsSendingMessage(state, payload) {
      state.isSendingMessage = payload;
    },
  },
  actions: {
    updateOfficeId({ commit }, officeId) {
      commit('setOfficeId', officeId); // Call the mutation to update officeId
    },
    // commit, state
    async [AUTH.ACTION_LOGIN]({ commit }, userData) {
      try {
        const { data, accessToken } = await authApi.postLogin(userData);
        if (!data || !accessToken) {
          return { error: "something wen't wrong" };
        }
        commit(AUTH.SET_AUTH, accessToken);
        commit(AUTH.SET_USER_DATA, data);
        return { data };
      } catch (error) {
        return { error: 'incorrect email or password' };
      }
    },
    async [AUTH.ACTION_VALIDATE_TOKEN]({ commit }, token) {
      // TODO: validate via api
      commit(AUTH.SET_AUTH, token);
    },
    async [AUTH.ACTION_LOGOUT]({ commit }) {
      // TODO:

      try {
        await authApi.postLogout();
        commit(AUTH.SET_AUTH, null);
      } catch (error) {
        message.error(error.response?.data?.message || error.mesage || "something wen't wrong");
      }
    },

    async [AUTH.ACTION_SET_USER_DATA]({ state, commit }) {
      if (state.userData) {
        return { data: state.userData };
      }

      try {
        const userPr = authApi.getUser();
        const rolesPr = authApi.getUserRoles();
        const [user, roles] = await Promise.all([userPr, rolesPr]);
        const userData = { user: user.data, roles: roles.data };
        commit(AUTH.SET_USER_DATA, userData);
        return { data: userData };
      } catch (error) {
        return { error: 'incorrect email or password' };
      }
    },
    setIsSendingMessage({ commit }, newValue) {
      commit('updateIsSendingMessage', newValue);
    },
  },

};
