import { MEDIA } from '../types';

export default {
  state: {
    mediaMediaQuery: null,
    mediaDocsQuery: null,
    mediaLinkQuery: null,
  },
  getters: {},
  mutations: {
    [MEDIA.SET_MEDIA_MEDIA_STATE](state, payload) {
      state.mediaMediaQuery = payload;
    },
    [MEDIA.SET_MEDIA_DOCS_STATE](state, payload) {
      state.mediaDocsQuery = payload;
    },
    [MEDIA.SET_MEDIA_LINK_STATE](state, payload) {
      state.mediaLinkQuery = payload;
    },

  },
  actions: {},
};
