const CAMPAIGNS = {
  SET_CAMPAIGNS_STATE: 'SET_CAMPAIGNS_STATE',
  SET_CAMPAIGN_STATE: 'SET_CAMPAIGN_STATE',
  SET_CAMPAIGNS_PAGES_STATE: 'SET_CAMPAIGNS_PAGES_STATE',
  SET_ACTIVE_CAMPAIGNS_QUERY: 'SET_ACTIVE_CAMPAIGNS_QUERY',
  SET_CAMPAIGN_TYPE_QUERY: 'SET_CAMPAIGN_TYPE_QUERY',
  SET_CAMPAIGN_BRANCHES_QUERY: 'SET_CAMPAIGN_BRANCHES_QUERY',
  SET_BRAND_STATE: 'SET_BRAND_STATE',
  SET_SUB_BRAND_STATE: 'SET_SUB_BRAND_STATE',
  SET_BRAND_BRANCH_STATE: 'SET_BRAND_BRANCH_STATE',
  SET_CAMPAIGNS_EXCLUDE_STATE: 'SET_CAMPAIGNS_EXCLUDE_STATE',
  SET_CAMPAIGNS_OBJECTIVE_STATE: 'SET_CAMPAIGNS_OBJECTIVE_STATE',
  SET_CAMPAIGNS_COVERAGE_STATE: 'SET_CAMPAIGNS_COVERAGE_STATE',
  SET_CONFIRMED_CAMPAIGNS_STATE: 'SET_CONFIRMED_CAMPAIGNS_STATE',
  SET_CAMPAIGNS_CONTACT_STATE: 'SET_CAMPAIGNS_CONTACT_STATE',
  SET_REJECT_REASONS_QUERY: 'SET_REJECT_REASONS_QUERY',
  SET_ALL_CAMPAIGNS_QUERY: 'SET_ALL_CAMPAIGNS_QUERY',
  SET_CAMPAIGN_CONTACTS_QUERY: 'SET_CAMPAIGN_CONTACTS_QUERY',
  SET_CAMPAIGN_STATUS_LIST_QUERY: 'SET_CAMPAIGN_STATUS_LIST_QUERY',
};

export default CAMPAIGNS;
