import { createApp } from 'vue';
import AsImage from '@awesome-image/image';
import { imageUrlGeneratorFP } from '@awesome-image/services';
import { ObserveVisibility } from 'vue-observe-visibility';

import { VueQueryPlugin } from 'vue-query';
import VueAxios from 'vue-axios';
import VueGates from 'vue-gates';

import FlagIcon from 'vue-flag-icon';
import mitt from 'mitt';
import App from './App.vue';
import router from './router';
import store from './store';
import apiAxios from './services/apiAxios';

import 'ant-design-vue/dist/antd.variable.min.css';
import '@/assets/styles/tailwind.css';
import '@/assets/styles/main.css';
import '@awesome-image/image/dist/style.css';

const app = createApp(App);
app.config.globalProperties.emitter = mitt();

app.directive('observe-visibility', ObserveVisibility);

const vueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  },
};

app.use(AsImage, {
  responsive: true,
  progressive: true,
  imageUrlGenerator: imageUrlGeneratorFP,
}).use(VueQueryPlugin, vueQueryPluginOptions)
  .use(FlagIcon)
  .use(store)
  .use(router);

app.use(VueAxios, apiAxios);
app.use(VueGates);
app.provide('axios', app.config.globalProperties.axios); // provide 'axios'

app.mount('#app');
