import { createRouter, createWebHistory } from 'vue-router';
import AuthView from '@/views/auth/AuthView.vue';
import LoginView from '@/views/auth/LoginView.vue';
import { authGurd, pagesGurd } from '@/middleware/gurds';

// roles => 1 admin | 2 user
const routes = [
  {
    path: '/chat',
    component: () => import('@/views/chat/ChatView.vue'),
    meta: { roles: [1, 2, 3] },
    beforeEnter: (to, from, next) => {
      pagesGurd(to, from, next);
    },
  },
  {
    path: '/office',
    component: () => import('@/views/offices/Office.vue'),
    meta: { roles: [1, 2, 3] },
    beforeEnter: (to, from, next) => {
      pagesGurd(to, from, next);
    },
  },
  {
    path: '/',
    component: () => import('@/views/HomeRouter.vue'),
    meta: { roles: [1] },
    beforeEnter: (to, from, next) => {
      pagesGurd(to, from, next);
    },
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/views/HomeView.vue'),
      },
      {
        path: 'campaigns',
        name: 'campaigns',
        component: () => import('@/views/campaigns/CampaignsView.vue'),
      },
      {
        path: 'campaigns/:campaignId',
        name: 'campaign',
        component: () => import('@/views/campaigns/CampaignView.vue'),
      }, {
        path: 'complaints',
        name: 'complaints',
        component: () => import('@/views/complaints/ComplaintView.vue'),
      },
      {
        path: 'scrape',
        name: 'scrape',
        component: () => import('@/views/scrape/ScrapeView.vue'),
      },
      {
        path: 'messages',
        name: 'messages',
        component: () => import('@/views/messages/MessagesView.vue'),
      },
      {
        path: 'employees',
        name: 'employees',
        component: () => import('@/views/emplyees/EmployeesView.vue'),
      },
      {
        path: 'contacts',
        name: 'contacts',
        component: () => import('@/views/contacts/ContactsView.vue'),
      },
      {
        path: 'replies',
        name: 'replies',
        component: () => import('@/views/replies/RepliesView.vue'),
      },
      {
        path: 'reviews',
        name: 'Reviews',
        component: () => import('@/views/review/ReviewView.vue'),
      },
    ],
  },

  {
    path: '/auth',
    name: 'auth',
    redirect: '/auth/login',
    component: AuthView,
    beforeEnter: (to, from, next) => {
      authGurd(to, from, next);
    },
    children: [
      {
        path: 'login',
        name: 'login',
        component: LoginView,
      },
      {
        path: '*',
        redirect: '/auth/login',
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
