import { EMPLOYEES } from '../types';

export default {
  state: {
    employeesQuery: null,
    departmentUsersQuery: null,
  },
  getters: {},
  mutations: {
    [EMPLOYEES.SET_EMPLOYEES_QUERY](state, payload) {
      state.employeesQuery = payload;
    },
    [EMPLOYEES.SET_DEPARTMENT_USERS_QUERY](state, payload) {
      state.departmentUsersQuery = payload;
    },
  },
  actions: {},
};
